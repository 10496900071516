import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/css/style.css'
import Particles from "vue3-particles";
// import '../public/css/bootstrap.css'
// import '../public/css/animate.css'
// import '../public/css/all.min.css'
// import '../public/css/flexslider.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Particles)
app.mount('#app')
