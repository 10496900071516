<template>      
      <loader v-if="loading"></loader>
      <navbar @onClicked="scrollToSection"></navbar>
      <scrollbar></scrollbar>
      
      <div id="page" style="z-index: 1000">
         <!-- header -->

         <Particles
            id="tsparticles"
            :options="background"
            :particlesInit="particlesInit"
         />

         <header class="header" style="position: absolute;right: 200px;width: 100px;margin-top: 25px;margin-left: -30px;">
            <div class="container" style="cursor: pointer;width: 100px;">
               <div class="navbar-brand">
                  <div class="row">
                     <div class="col-xs-6">
                        <h1 class="text-left"></h1>
                     </div>
                  </div>                  
                  
                  <a href="#" class="js-nav-toggle" 
                     style="position: absolute;right:0;font-size: 20pt;z-index: 1000;" :style="{'right': isDesktop + 'svw'}">
                     <input id="checkbox" type="checkbox">
                     <label class="toggle" for="checkbox">
                        <div id="bar1" class="bars"></div>
                        <div id="bar2" class="bars"></div>
                        <div id="bar3" class="bars"></div>
                     </label>
                     <!-- <i class="fa-solid fa-bars menu-color"></i> -->
                  </a>
               </div>
            </div>
         </header>

         <a class="logo" href="/" style="position: absolute;top: 5svh;left: 10svw;z-index: 100;">
            <img src="/images/logo.png" style="width: 3svw">
         </a>

         <!-- Banner section -->
         <section id="banner" class="js-fullheight">
            <div class="flexslider js-fullheight" style="position: relative;">

               <div style="position: absolute;bottom: 30px;right: 30px;z-index: 100;">
                  <div style="position: relative;display: flex;justify-content: center;align-items: center;">
                     <input v-model="switching" id="switch-checkbox" type="checkbox" />
                     <label class="switch" for="checkbox">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="slider">
                        <path
                           d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
                        ></path>
                     </svg>
                     </label>
                  </div>
               </div>

               <!-- Social -->
               <ul class="list-unstyled ml-5 mt-3 position-relative social-links" style="z-index: 10;">
                  <li class="mb-3"><a target="blank" href="https://github.com/grgustiawan"><i class="fab fa-github"></i></a></li>
                  <li class="mb-3"><a target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146"><i class="fab fa-linkedin"></i></a></li>
                  <li class="mb-3"><a target="blank" href="https://www.instagram.com/gr.gustiawan/"><i class="fab fa-instagram"></i></a></li>
               </ul>

               <!-- Slider -->
               <ul class="slides" id="bg-switch"  style="position: relative;overflow: hidden;">
                  <li :class="{'bg-desktop-on': swidth > 768 && !isVisible, 'bg-desktop-off': swidth > 768 && isVisible, 'bg-mobile': swidth < 767}">
                     <!-- 
                     <div style="position: absolute;width: 100%;height: 100%;z-index: 1;left: 0;top: 0;opacity: 20%;" class="gradient-bg"></div>
                     -->

                     <div class="searchlight"
                     :class="{ on: isActive }"
                     :style="searchlightStyle"
                     @mousemove="handleMouseMove"
                     @mouseout="handleMouseOut"
                     v-if="isVisible"></div>

                     <div class="overlay-gradient"></div>
                     
                     <div class="container">
                        <div class="col-md-8 slider-text">
                           <div class="slider-text-inner">
                              <div class="desc">
                                 <h2 v-if="isVisible" style="color: black">I'm Galih Raka Gustiawan!</h2>
                                 <h2 v-if="!isVisible">I'm Galih Raka Gustiawan!</h2>
                                 <h3 style="color: brown;">I am a
                                    <span id="typed-slide-1"></span>
                                    <span id="typed-strings-slide-1">
                                    <span>GO Developer</span>
                                    <span>Node.js Developer</span>
                                    <span>Senior Software Engineer</span>
                                    </span>
                                 </h3>

                                 <button v-if="!isVisible" class="download-button">
                                    <a class="button_lg" style="text-decoration: none;"
                                       :href="downloadLink" download="CV_GALIH_RAKA_GUSTIAWAN.pdf">
                                        <span class="button_sl"></span>
                                        <span class="button_text">Download CV</span>
                                    </a>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>

                  </li>
               </ul>
            </div>
         </section>
         <!-- End Banner Section -->

         <!-- About Section -->
         <section id="about" style="position: relative;z-index: 100;">
            <div class="container">
               <div class="row">
             
                  <div class="col-md-12">
                     <div class="post-entry">

                        <div class="col-md-6">
                            <div class="header-page-about">
                              <h2 class="intro-heading">About Me</h2>
                           </div>
                            <p>Hello, I'm Galih Raka Gustiawan, a Software Engineer with a passion for crafting innovative and efficient digital solutions. My journey in software engineering has equipped me with a diverse skill set in programming languages such as JavaScript, TypeScript and GO. I staying up-to-date with the latest trends and best practices to deliver cutting-edge solutions that meet and exceed client expectations.</p>
                           <div class="col-md-6 col-sm-4 no-padding">
                              <ul>
                                 <li><span>Name : </span> Galih Raka Gustiawan</li>
                                 <li><span>Job : </span> Software Engineer</li>
                              </ul>
                           </div>
                           <div class="col-md-6 col-sm-4 no-padding">
                              <ul>
                                 <li><span>Email : </span> <a href="mailto:galihrakagustiawan@gmail.com">galihrakagustiawan@gmail.com</a></li>
                                 <li><span>Phone : </span> <a href="tel:085179793322"> +62 851 7979 3322</a></li>
                              </ul>
                           </div>
                           <hr>
                           <div class="col-md-12 no-padding mt-20">
                              <a class="btn btn" target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146/">Profile</a>
                              <a class="btn btn-primary" href="tel:085179793322">Hire Me</a> 
                           </div>
                        </div>

                        <div class="col-md-6">
                           <div class="post-image" style="background-image: url('images/profile-pic.jpg');"></div>
                           <div class="display-tc">
                              <div class="desc">
                                 <h3>My Skill</h3>
                                 <div class="skill-item">
                                    <h5>GO</h5><span>100%</span>
                                    <div class="progress" data-percent="70%">
                                       <div class="progress-bar bar-null" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                                    </div>
                                 </div>
                                 <div class="skill-item">
                                    <h5>Node.Js</h5><span>100%</span>
                                    <div class="progress" data-percent="100%">
                                       <div class="progress-bar bar-null" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </section>
         <!-- End About Section -->

         <!-- Resume Section -->
         <section id="resume" class="bg-dark bg-dots" style="position: relative;z-index: 100;">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Resume</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">RESUME</span>
                     <p>Results-driven Software Engineer with a years of experience. Proven track record of FullStack Engineer. Adept at JavaScript, TypeScript, and PHP.</p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12">
                     <div class="col-md-6">
                        <div class="item mb-50">
                           <h4><span class="item-icon"><i class="fas fa-briefcase"></i></span>Senior Software Engineer</h4>
                           <span class="meta">01.2024 - Now</span>
                           <h5>PT. SARI TIRTA INDONESIA (Melawai)</h5>
                           <p>
                              <ul style="margin-left: -20px;">
                                 <p>I am enthusiastic about driving technological advancements, staying abreast of industry trends, and ensuring that our software development practices remain cutting-edge. Through a combination of leadership, technical prowess, and a dedication to excellence, I am confident in my ability to elevate and advance the software development function within the organization.</p>
                              </ul>
                           </p>
                        </div>
                        <div class="item mb-50 mt-20">
                           <h4><span class="item-icon"><i class="fas fa-briefcase"></i></span>Software Engineer</h4>
                           <span class="meta">10.2022 - 01.2024</span>
                           <h5>PT. SARI TIRTA INDONESIA (Melawai)</h5>
                           <p>
                              <ul style="margin-left: -20px;">
                                 <li>Analysis of client needs and create a system design.</li>
                                 <li>Determine the technology stack to be used.</li>
                                 <li>Create a database structure.</li>
                                 <li>Developing Backend and Frontend Application.</li>
                                 <li>Integrating Application with third party vendor.</li>
                                 <li>Deploying apps to production.</li>
                              </ul>
                           </p>
                        </div>
                     </div>

                     <div class="col-md-6">
                        <div class="item mb-50">
                           <h4><span class="item-icon"><i class="fas fa-graduation-cap"></i></span>Teknik Informatika</h4>
                           <span class="meta">07.2017 - 08.2022</span>
                           <h5>Universitas Banten Jaya</h5>
                           <p>Master the art of software development, from conceptualization to implementation, honing skills vital for crafting robust and scalable solutions.</p>
                        </div>
                        <div class="item mb-50" style="margin-top: 65px">
                           <h4><span class="item-icon"><i class="fas fa-graduation-cap"></i></span>Teknik Komputer Jaringan</h4>
                           <span class="meta">07.2011 - 07.2014</span>
                           <h5>SMKN 1 Kota Serang </h5>
                           <p>Develop a deep understanding of network protocols, security, and administration, becoming a proficient architect of secure and efficient network infrastructures.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Resume Section -->

         <!-- Services Section -->
         <section id="services" class="bg-light">
            <div class="container" style="position: relative;z-index: 100;">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Skill</h2>
                     </div>
                     <span class="heading-meta-sub">SKILL SET</span>
                     <p>
                        Software Engineer expertise in JavaScript and GO, adept at crafting efficient and innovative solutions.
                     </p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fab fa-codepen"></i>
                        </div>
                        <div class="box-num">
                           <h2>01</h2>
                        </div>
                        <div class="text">
                           <h3>Design System</h3>
                           <p>Turn your vision into reality with precision-driven system design.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-laptop-code"></i>
                        </div>
                        <div class="box-num">
                           <h2>02</h2>
                        </div>
                        <div class="text">
                           <h3>BackEnd Development</h3>
                           <p>Write clean code using JavaScript and GO.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-copy"></i>
                        </div>
                        <div class="box-num">
                           <h2>03</h2>
                        </div>
                        <div class="text">
                           <h3>Optimal Backup Solutions</h3>
                        </div>
                        <div class="text-justify" style="width: 80%;display: flex;justify-content: center;width: 100%;">
                           <p style="font-size: 10pt;width: 80%;">Design an optimal backup strategy that ensures data integrity, minimizes downtime, and allows for quick recovery in the event of data loss.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service" style="height: 190px;">
                        <div class="icon">
                           <i class="fas fa-chart-line"></i>
                        </div>
                        <div class="box-num">
                           <h2>04</h2>
                        </div>
                        <div class="text">
                           <h3>System Optimization</h3>
                           <p>Enhanced performance through strategic optimization.</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service">
                        <div class="icon">
                           <i class="fas fa-desktop"></i>
                        </div>
                        <div class="box-num">
                           <h2>05</h2>
                        </div>
                        <div class="text">
                           <h3>Integrating Apps</h3>
                           <p>Integrating apps with third party vendor using api. </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="service">
                        <div class="icon">
                           <i class="fas fa-rocket"></i>
                        </div>
                        <div class="box-num">
                           <h2>06</h2>
                        </div>
                        <div class="text">
                           <h3>Deploying Application</h3>
                           <p>Deploying apps to production server. </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Resume Section -->
         
         <!-- Counters Section -->
         <!-- <div class="counters" id="counter-animate">
            <div class="narrow-content">
               <div class="container">
               <div class="row" >
                  <div class="col-md-12"> 
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="7520" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Project</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="5450" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Clients</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="6876" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Happy Client</span>
                     </div>
                     <div class="col-md-3 text-center">
                        <span class="counter js-counter" data-from="0" data-to="456" data-speed="5000" data-refresh-interval="50"></span>
                        <span class="counter-label">Awards </span>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div> -->
         <!-- End Counters Section -->

         <!-- Works Section -->
         <section id="work" class="bg-light">
            <div class="container" style="position: relative;z-index: 100;">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>My Projects</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">PORTFOLIO</span>
                     <p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 px-25 text-center">
                     <ul class="work-filter">
                        <li class="filter" :class="{'active' : selected_project == 'App'}" data-filter=".branding" @click="selected_project = 'App'">Application</li>
                        <li class="filter all" :class="{'active' : selected_project == 'Stack'}" data-filter="*" @click="selected_project = 'Stack'">Tech Stack</li>
                        <li class="filter" :class="{'active' : selected_project == 'Database'}" data-filter=".web" @click="selected_project = 'Database'">Tools</li>
                     </ul>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'App'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/sos.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://tamani-pos.online">Self Ordering System</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web branding">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ess.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://saritirta-group.com">ESS</a>
                                    </h3>
                                    <span>Sari Tirta Indonesia</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/tamani-reservasi.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://reservasi.tamani-pos.online">Tamani Reservation</a>
                                    </h3>
                                    <span>Tamani Restaruant</span>
                                 </div>
                              </div>
                           </div>
                        </div>          
                        <div class="col-lg-4 col-md-6 col-sm-12 all branding web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ecommerce.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://apotikmelawai.id">E-Commerce</a>
                                    </h3>
                                    <span>Apotik Melawai</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/procurement.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://procurement.saritirta-group.com">Procurement</a>
                                    </h3>
                                    <span>Movenpick</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all photo mobile">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/ose.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://ose.saritirta-group.com">OSE</a>
                                    </h3>
                                    <span>Movenpick</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/erp-sti.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://erp.saritirta-group.com">ERP Sari Tirta Group</a>
                                    </h3>
                                    <span>Sari Tirta Indonesia</span>
                                 </div>
                              </div>
                           </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/menu-tamani.jpg" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Menu Book</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div>  
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="/images/tamani_cms.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Tamani CMS</a>
                                    </h3>
                                    <span>Tamani Restaurant</span>
                                 </div>
                              </div>
                           </div>
                        </div> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/payment.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a href="https://mjcc.ruangmahakarya.com">MJCC Payment</a>
                                    </h3>
                                    <span>Movenpick Hotel</span>
                                 </div>
                              </div>
                           </div>
                        </div>   
                     </div>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'Stack'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="/images/vuejs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Vue Js</a>
                                    </h3>
                                    <span>JavaScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/expressjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Express Js</a>
                                    </h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/nestjs.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Nest JS</a>
                                    </h3>
                                    <span>TypeScript</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/go.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>GO</a></h3>
                                    <span>GO</span>
                                 </div>
                              </div>
                           </div>
                        </div>    
                     </div>
                  </div>

                  <div class="col-lg-12" v-if="selected_project === 'Database'">
                     <div class="work-gallery"> 
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/sql-server.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>Microsoft SQL Server</a>
                                    </h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden;">
                           <div class="work">
                              <img class="img-fluid" src="../assets/images/postgre.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3>
                                       <a>PostgreSQL</a>
                                    </h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/mongo.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Mongo DB</a></h3>
                                    <span>NoSQL</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/cassandra.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Apache Cassandra</a></h3>
                                    <span>Wide Column</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/mysql.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>MySQL</a></h3>
                                    <span>Relational</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/rabbitmq.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>RabbitMQ</a></h3>
                                    <span>Messaging</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/redis.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Redis</a></h3>
                                    <span>Cache</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/docker.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Docker</a></h3>
                                    <span>Container</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/prometheus.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Prometheus</a></h3>
                                    <span>Monitoring</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/grafana.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Grafana</a></h3>
                                    <span>Monitoring</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/pm2.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>PM 2</a></h3>
                                    <span>Manager</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 all web" style="overflow: hidden">
                           <div class="work" style="color:white">
                              <img class="img-fluid" src="../assets/images/nginx.png" alt="">
                              <div class="desc">
                                 <div class="con">
                                    <h3><a>Nginx</a></h3>
                                    <span>Proxy</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Works Section -->

         <!-- Contact Section -->
         <section id="contact" class="bg-light" style="position: relative;z-index: 100;">
            <div class="container">
               <div class="row">
                  <div class="col-md-8 col-md-offset-2 text-center heading">
                     <div class="header-page">
                        <h2>Get In Touch</h2>
                        <span></span>
                     </div>
                     <span class="heading-meta-sub">CONTACT</span>
                     <p>Have a question or need more information about my work or experiences? Feel free to ask!</p>
                  </div>
               </div>
               <div class="row" style="display: flex;justify-content: space-between;">
                  <div class="col-md-4 col-md-push-1">
                     <ul class="contact-info">
                        <li><i class="fas fa-map-marked-alt"></i>Serang, Banten - Indonesia</li>
                        <li><i class="fas fa-phone-alt"></i>+62 851-7979-3322</li>
                        <li><i class="fas fa-envelope"></i><a href="mailto:galihrakagustiawan@gmail.com">galihrakagustiawan@gmail.com</a></li>
                        <li><i class="fas fa-globe"></i><a href="https://galihrakagustiawan.site">www.galihrakagustiawan.site</a></li>
                     </ul>
                  </div>
                  <div class="col-md-5 col-md-push-1">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="text" class="form-control" placeholder="Name" v-model="name">
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="text" class="form-control" placeholder="Email" v-model="email">
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <textarea name="message" class="form-control" id="message" cols="30" rows="7" placeholder="Message" v-model="message"></textarea>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group text-right">
                              <input type="submit" value="Send Message" class="btn btn-primary" @click="sendEmail">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- End Contact Section -->

         <!-- Footer -->
         <footer>
            <div id="footer">
               <div class="container" style="position: relative;z-index: 100;">
                  <div class="row copy-right">
                     <div class="col-md-6 col-md-offset-3 text-center">
                        <h2><a class="footer-logo">Galih Raka Gustiawan</a></h2>
                        <p class="social-icon">
                           <a target="blank" href="https://github.com/grgustiawan"><i class="fab fa-github"></i></a>
                           <a target="blank" href="https://www.instagram.com/gr.gustiawan"><i class="fab fa-instagram"></i></a>
                           <a target="blank" href="https://www.linkedin.com/in/galih-raka-gustiawan-003111146"><i class="fab fa-linkedin"></i></a>
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <a v-if="sheight > 400" id="back-to-top" href="#"><i class="fas fa-chevron-up"></i></a>
         </footer>
         <!-- End Footer -->
      </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Navbar from '@/components/Navbar.vue'
import Scrollbar from '@/components/Scrollbar.vue'
import { loadSlim } from 'tsparticles-slim';
import emailjs from 'emailjs-com'

export default {
  name: 'HomeView',
  components: {
    Loader,
    Navbar,
    Scrollbar,
  },
  data(){
    return {
      loading: true,
      switching: true,
      selected_project: "App",
      swidth: 0,
      sheight: 0,
      downloadLink: 'https://galihrakagustiawan.site/files/CV_GALIH_RAKA_GUSTIAWAN.pdf',
      name: null,
      email: null,
      message: null,
      isDesktop: -5,
      background: {
            autoPlay: true,
            background: {
              color: {
                value: "transparent"
              },
              image: "",
              position: "",
              repeat: "",
              size: "",
              opacity: 1
            },
            backgroundMask: {
              composite: "destination-out",
              cover: {
                color: {
                  value: "#fff"
                },
                opacity: 1
              },
              enable: false
            },
            clear: true,
            defaultThemes: {},
            delay: 0,
            fullScreen: {
              enable: true,
              zIndex: 1
            },
            detectRetina: true,
            duration: 0,
            fpsLimit: 120,
            interactivity: {
              detectsOn: "window",
              events: {
                onClick: {
                  enable: true,
                  mode: "push"
                },
                onDiv: {
                  selectors: [],
                  enable: false,
                  mode: [],
                  type: "circle"
                },
                onHover: {
                  enable: true,
                  mode: "grab",
                  parallax: {
                    enable: true,
                    force: 60,
                    smooth: 10
                  }
                },
                resize: {
                  delay: 0.5,
                  enable: true
                }
              },
              modes: {
                trail: {
                  delay: 1,
                  pauseOnStop: false,
                  quantity: 1
                },
                attract: {
                  distance: 200,
                  duration: 0.4,
                  easing: "ease-out-quad",
                  factor: 1,
                  maxSpeed: 50,
                  speed: 1
                },
                bounce: {
                  distance: 200
                },
                bubble: {
                  distance: 400,
                  duration: 2,
                  mix: false,
                  opacity: 0.8,
                  size: 40,
                  divs: {
                    distance: 200,
                    duration: 0.4,
                    mix: false,
                    selectors: []
                  }
                },
                connect: {
                  distance: 80,
                  links: {
                    opacity: 0.5
                  },
                  radius: 60
                },
                grab: {
                  distance: 400,
                  links: {
                    blink: false,
                    consent: false,
                    opacity: 1
                  }
                },
                push: {
                  default: true,
                  groups: [],
                  quantity: 4
                },
                remove: {
                  quantity: 2
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                  factor: 100,
                  speed: 1,
                  maxSpeed: 50,
                  easing: "ease-out-quad",
                  divs: {
                    distance: 200,
                    duration: 0.4,
                    factor: 100,
                    speed: 1,
                    maxSpeed: 50,
                    easing: "ease-out-quad",
                    selectors: []
                  }
                },
                slow: {
                  factor: 3,
                  radius: 200
                },
                light: {
                  area: {
                    gradient: {
                      start: {
                        value: "#ffffff"
                      },
                      stop: {
                        value: "#000000"
                      }
                    },
                    radius: 1000
                  },
                  shadow: {
                    color: {
                      value: "#000000"
                    },
                    length: 2000
                  }
                }
              }
            },
            manualParticles: [],
            particles: {
              bounce: {
                horizontal: {
                  value: 1
                },
                vertical: {
                  value: 1
                }
              },
              collisions: {
                absorb: {
                  speed: 2
                },
                bounce: {
                  horizontal: {
                    value: 1
                  },
                  vertical: {
                    value: 1
                  }
                },
                enable: false,
                maxSpeed: 50,
                mode: "bounce",
                overlap: {
                  enable: true,
                  retries: 0
                }
              },
              color: {
                value: "#e1b783",
                animation: {
                  h: {
                    count: 0,
                    enable: false,
                    speed: 1,
                    decay: 0,
                    delay: 0,
                    sync: true,
                    offset: 0
                  },
                  s: {
                    count: 0,
                    enable: false,
                    speed: 1,
                    decay: 0,
                    delay: 0,
                    sync: true,
                    offset: 0
                  },
                  l: {
                    count: 0,
                    enable: false,
                    speed: 1,
                    decay: 0,
                    delay: 0,
                    sync: true,
                    offset: 0
                  }
                }
              },
              effect: {
                close: true,
                fill: true,
                options: {},
                type: []
              },
              groups: {},
              move: {
                angle: {
                  offset: 0,
                  value: 90
                },
                attract: {
                  distance: 200,
                  enable: false,
                  rotate: {
                    x: 3000,
                    y: 3000
                  }
                },
                center: {
                  x: 50,
                  y: 50,
                  mode: "percent",
                  radius: 0
                },
                decay: 0,
                distance: {},
                direction: "none",
                drift: 0,
                enable: true,
                gravity: {
                  acceleration: 9.81,
                  enable: false,
                  inverse: false,
                  maxSpeed: 50
                },
                path: {
                  clamp: true,
                  delay: {
                    value: 0
                  },
                  enable: false,
                  options: {}
                },
                outModes: {
                  default: "out",
                  bottom: "out",
                  left: "out",
                  right: "out",
                  top: "out"
                },
                random: false,
                size: false,
                speed: 2,
                spin: {
                  acceleration: 0,
                  enable: false
                },
                straight: false,
                trail: {
                  enable: false,
                  length: 10,
                  fill: {}
                },
                vibrate: false,
                warp: false
              },
              number: {
                density: {
                  enable: true,
                  width: 1920,
                  height: 1080
                },
                limit: {
                  mode: "delete",
                  value: 0
                },
                value: 200
              },
              opacity: {
                value: {
                  min: 0.1,
                  max: 0.5
                },
                animation: {
                  count: 0,
                  enable: true,
                  speed: 3,
                  decay: 0,
                  delay: 0,
                  sync: false,
                  mode: "auto",
                  startValue: "random",
                  destroy: "none"
                }
              },
              reduceDuplicates: false,
              shadow: {
                blur: 0,
                color: {
                  value: "#000"
                },
                enable: false,
                offset: {
                  x: 0,
                  y: 0
                }
              },
              shape: {
                close: true,
                fill: true,
                options: {},
                type: "circle"
              },
              size: {
                value: {
                  min: 1,
                  max: 5
                },
                animation: {
                  count: 0,
                  enable: true,
                  speed: 20,
                  decay: 0,
                  delay: 0,
                  sync: false,
                  mode: "auto",
                  startValue: "random",
                  destroy: "none"
                }
              },
              stroke: {
                width: 0
              },
              zIndex: {
                value: 0,
                opacityRate: 1,
                sizeRate: 1,
                velocityRate: 1
              },
              destroy: {
                bounds: {},
                mode: "none",
                split: {
                  count: 1,
                  factor: {
                    value: 3
                  },
                  rate: {
                    value: {
                      min: 4,
                      max: 9
                    }
                  },
                  sizeOffset: true,
                  particles: {}
                }
              },
              roll: {
                darken: {
                  enable: false,
                  value: 0
                },
                enable: false,
                enlighten: {
                  enable: false,
                  value: 0
                },
                mode: "vertical",
                speed: 25
              },
              tilt: {
                value: 0,
                animation: {
                  enable: false,
                  speed: 0,
                  decay: 0,
                  sync: false
                },
                direction: "clockwise",
                enable: false
              },
              twinkle: {
                lines: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1
                },
                particles: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1
                }
              },
              wobble: {
                distance: 5,
                enable: false,
                speed: {
                  angle: 50,
                  move: 10
                }
              },
              life: {
                count: 0,
                delay: {
                  value: 0,
                  sync: false
                },
                duration: {
                  value: 0,
                  sync: false
                }
              },
              rotate: {
                value: 0,
                animation: {
                  enable: false,
                  speed: 0,
                  decay: 0,
                  sync: false
                },
                direction: "clockwise",
                path: false
              },
              orbit: {
                animation: {
                  count: 0,
                  enable: false,
                  speed: 1,
                  decay: 0,
                  delay: 0,
                  sync: false
                },
                enable: false,
                opacity: 1,
                rotation: {
                  value: 45
                },
                width: 1
              },
              links: {
                blink: false,
                color: {
                  value: "#d6d3d1"
                },
                consent: false,
                distance: 150,
                enable: true,
                frequency: 1,
                opacity: 0.4,
                shadow: {
                  blur: 5,
                  color: {
                    value: "#000"
                  },
                  enable: false
                },
                triangles: {
                  enable: false,
                  frequency: 1
                },
                width: 1,
                warp: false
              },
              repulse: {
                value: 0,
                enabled: false,
                distance: 1,
                duration: 1,
                factor: 1,
                speed: 1
              }
            },
            pauseOnBlur: true,
            pauseOnOutsideViewport: true,
            responsive: [],
            smooth: false,
            style: {
              width: "100%",
              height: "100%"
            },
            themes: [],
            zLayers: 100,
            name: "Parallax",
            motion: {
              disable: false,
              reduce: {
                factor: 4,
                value: true
              }
            }
      },
      isActive: false,
      isVisible: false,
      searchlightStyle: {
        marginLeft: '0px',
        marginTop: '0px',
      },
    }
  },
  watch: {
      switching(){
         if(this.switching) {
            document.getElementById("bg-switch").classList.add("fade-in");

            setTimeout(() => {
               this.isVisible = false;
            }, 100)
         } else {
            this.isVisible = true;
            document.getElementById("bg-switch").classList.remove("fade-in");
         }
      }
  },
  created(){
   this.swidth = screen.width
   
   window.addEventListener('scroll', this.handleScroll);

   if(this.swidth < 768){
      this.isDesktop = 0;
   }

   setTimeout(() => {
   this.loading = false
   }, 1500)
  },
  methods: {
      handleScroll(){
         this.sheight = window.scrollY
      },
      handleMouseMove(event) {
         this.isActive = true;
         this.searchlightStyle.marginLeft = `${event.pageX - 150}px`;
         this.searchlightStyle.marginTop = `${event.pageY - 150}px`;
      },
      handleMouseOut() {
         this.isActive = false;
      },
      handleClick() {
         this.isVisible = false;
      },
      async particlesInit(engine) {
         await loadSlim(engine)
      },
      scrollToSection(value) {
         document.body.classList.remove('menu-show')
         document.getElementById("navButton").classList.remove('active')

         let unit = value.unit
         if(screen.height <= 720){
            unit += 6
         }

         const offset = -(screen.height / unit);
         const section = document.getElementById(value.id);
         if(section) {
            const scrollPosition = section.offsetTop + (offset || 0);
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
         }
      },
      sendEmail() {
         const templateParams = {
            to_name: 'Galih Raka Gustiawan',
            from_name: this.name,
            message: this.message,
            reply_to: this.email
         };

         emailjs.send('service_0st7bwz', 'template_pemkwvo', templateParams, 'ZABaGgpX2DnMNZl_W')
         .then((response) => {
            alert('Email sent successfully:', response);
         })
         .catch((error) => {
            alert('Error sending email:', error);
         });
      }
  }
}
</script>

<style>
.bg-desktop-on{
   background-image: url('../assets/images/slide_1.jpg');
}

.bg-desktop-off{
   background-image: url('../assets/images/slide_3.jpg');
}

.bg-mobile{
   background-image: url('../assets/images/slide_2.jpg');
}


.gradient-bg {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgba(101, 0, 94, 1) 3%,
    rgba(60, 132, 206, 1) 38%,
    rgba(48, 238, 226, 1) 68%,
    rgba(255, 25, 25, 1) 98%
  );
  animation: gradient 10s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}


.searchlight {
    position: absolute;
    z-index: 10;
    height: 800px;
    width: 800px;
    border-width: 100vh 100vw;
    border-style: solid;
    border-color: #000;
    top: -100vh;
    left: -100vw;
    cursor: none;
    background: #000;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -ms-box-sizing: content-box;
    box-sizing: content-box;
}

.searchlight.on {
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%); /* FF3.6+ */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* IE10+ */
    background: radial-gradient(ellipse at center,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%); /* W3C */
}

/* From Uiverse.io by vinodjangid07 */ 
#switch-checkbox {
   position: absolute;
   margin: 0 auto;
   z-index: 100;
   width: 100%;
   height: 100%;
   opacity: 0;
   cursor: pointer;
}

.switch {
  position: relative;
  width: 70px;
  height: 70px;
  background-color: #e1b783;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px rgb(2, 2, 2) inset;
  opacity: 80%;
}

.switch svg {
  width: 1.2em;
}
.switch svg path {
  fill: rgb(48, 48, 48);
}
#switch-checkbox:checked + .switch {
  box-shadow: 0px 0px 1px rgb(151, 243, 255) inset,
    0px 0px 2px rgb(151, 243, 255) inset, 0px 0px 10px rgb(151, 243, 255) inset,
    0px 0px 40px rgb(151, 243, 255), 0px 0px 100px rgb(151, 243, 255),
    0px 0px 5px rgb(151, 243, 255);
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(146, 180, 184);
}
#switch-checkbox:checked + .switch svg {
  filter: drop-shadow(0px 0px 5px rgb(151, 243, 255));
}

#switch-checkbox:checked + .switch svg path {
  fill: rgb(255, 255, 255);
}

.fade-in {
    animation: fade-in 1s ease;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>